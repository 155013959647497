<template>
	<el-form
		ref="itemForm"
		class="option-item-container"
		:model="formData"
		:rules="rules"
	>
		<el-form-item :label="itemIndex == 0 ? 'RU' : ''" prop="title_ru">
			<!-- <label v-if="itemIndex == 0">RU</label> -->
			<el-input v-model="formData.title_ru" placeholder="Название"> </el-input>
		</el-form-item>

		<el-form-item :label="itemIndex == 0 ? 'HE' : ''" prop="title_uk">
			<!-- <label v-if="itemIndex == 0">UK</label> -->
			<el-input v-model="formData.title_uk" placeholder="Назва"> </el-input>
		</el-form-item>

		<el-form-item
			v-if="additionalOptionsList"
			:label="itemIndex == 0 ? 'Варианты атрибутов' : ''"
			prop="attribute_options_ids"
		>
			<!-- <label v-if="itemIndex == 0">UK</label> -->
			<el-select
				v-model="formData.attribute_options_ids"
				class="with-tags"
				multiple
				filterable
				placeholder="выберите атрибуты"
				:disabled="!additionalOptionsList.length"
			>
				<el-option
					v-for="item in additionalOptionsList"
					:key="'attribute_option_id-' + item.id"
					:label="item.title_ru"
					:value="item.id"
				/>
			</el-select>
		</el-form-item>

		<el-button
			class="action-button"
			size="mini"
			type="danger"
			icon="icomoon icon-cross"
			@click="removeItem"
		/>
	</el-form>
</template>

<script>
import { updateFormData /* , prepareSubmitData */ } from '@/helpers';
import { required } from '@/constants/validation';

export default {
	props: {
		itemIndex: Number,
		// clearable: Boolean,
		// query: String,
		itemData: {
			type: Object,
			required: true
		},

		additionalOptionsList: {
			type: Array
		},
		formDataProp: String
	},

	data() {
		return {
			new: false,
			itemId: null,

			formData: {
				id: null,
				title_ru: '',
				title_uk: '',
				attribute_options_ids: []
			}
		};
	},

	computed: {
		rules: () => ({
			title_uk: required,
			title_ru: required
		})
	},

	created() {
		this.setupPage(this.itemData);

		// console.log('created')
		// this.searchQuery = this.query ? this.query : '';
	},

	methods: {
		setupForm(itemData, formData) {
			// formData.attribute_options_ids = [...itemData.attributeOptions];
			let additionalRules = {};

			if (itemData.attribute_options) {
				additionalRules.attribute_options_ids = [];

				for (const option of itemData.attribute_options) {
					additionalRules.attribute_options_ids.push(option.id);
				}
			}
			return updateFormData(itemData, formData, additionalRules);
		},

		setupPage(item) {
			this.new = item.new || false;
			this.itemId = item.id;
			this.formData = this.setupForm(item.data, this.formData);
		},

		validateItemForm() {
			this.$refs['itemForm'].validate(valid => {
				let payload = {
					data: this.formData,
					formDataProp: this.formDataProp || 'attribute_options',
					isValid: valid
				};

				if (!this.additionalOptionsList) {
					delete payload.data.attribute_options_ids;
				}
				// console.log(payload);
				this.$emit('ready', payload);
			});
		},

		removeItem() {
			// this.$emit('onRemove', this.itemId);
			this.$emit('onRemove', { id: this.itemId, listName: 'optionsList' });
		}
	}
};
</script>
